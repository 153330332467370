/* Total Styles */

/* background-color: #fc5296;
background-image: linear-gradient(315deg, #fc5296 0%, #f67062 74%); */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibrbacki, "Trebuchet MS",
    sans-serif;
  color: white;
}

body {
  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
  height: 100vh; 
}

/* Navbar Styles */

ul {
  list-style-type: none;
}

h1 {
  padding: 0.5rem;
  font-size: 6rem;
}

/* background-color: #5b6467;
  background-image: linear-gradient(315deg, #5b6467 0%, #8b939a 74%); */

.highlight-color {
  display: inline-block;
  color: #333;
  background-color: #5b6467;
  background-image: linear-gradient(315deg, #5b6467 0%, #8b939a 74%);
  border-radius: 5px;
  padding: 0.5rem;
  margin: 0.5rem;
}

.highlight-color-text {
  color: #fff;
}

.navbar-header {
  display: flex;
  justify-content: space-between;
  margin: 3rem;
}

.navbar-list {
  display: flex;
}

.navbar-list li {
  padding: 1rem 1.5rem;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 3.2rem;
}

.navbar-list li:hover {
}

.navbar-list a:hover {
  /* color: #ffdc00; */
  background-color: #5b6467;
  background-image: linear-gradient(315deg, #5b6467 0%, #8b939a 74%);
  transition: ease-in-out 0.2s;
}

.navbar-list a {
  text-decoration: none;
  border: none;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
}

/* Searchbar Styles */

.searchbar-wrapper{
  display: flex;
  justify-content: center;
  margin-right: 8rem;
}

.searchbar {
  display: flex;
  margin-top: 3rem;
  max-width: 2000px;
  color: #fff;
  height: 8rem;
}

.searchbar-input {
  padding-top: 2rem;
  padding-left: 2rem;
  width: 100%;
  margin-top: 1.4rem;
}

.searchbar-input input {
  font-size: 3rem;
  width: 100%;
  padding-left: 2rem;
  border-radius: 10px;
  height: 100%;
  color: #414141;
  outline: none;
  border: unset;
}

.searchbar-btn {
  display: inline-block;
  font-size: 8rem;
  margin-top: 2rem;
  margin-left: 2rem;
  margin-right: 0rem;
  color: #fff;
}

.searchbar-btn:hover {
  cursor: pointer;
}

/* Stats Section Styles */

.user-info {
  margin-bottom: 3rem;
  display: block;
  background-color: #5b6467;
  background-image: linear-gradient(315deg, #5b6467 0%, #8b939a 74%);
  text-align: center;
  border-radius: 5px;
  padding-bottom: 1rem;
}

.username p {
  font-size: 2rem;
}

.username img {
  border: #333 1px solid;
  margin-top: 2rem;
  border-radius: 50%;
}

.flex {
  display: flex;
}

.flex p {
  font-size: 1.6rem;
  padding-left: 1rem;
}

.stats-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10rem 3rem;
}

.overall {
  background-color: #5b6467;
  background-image: linear-gradient(315deg, #5b6467 0%, #8b939a 74%);
  margin-bottom: 3rem;
  padding: 2rem;
  border-radius: 5px;
}

.overall-stats {
  display: grid;
  padding-top: 3rem;
  padding-left: 1rem;
  grid-template-columns: 1.5fr 1fr;
  line-height: 3rem;
  font-size: 1.1rem;
  font-weight: bold;
}

.ranked {
  background-color: #5b6467;
  background-image: linear-gradient(315deg, #5b6467 0%, #8b939a 74%);
  margin-bottom: 3rem;
  padding: 2rem;
  border-radius: 5px;
}

.ranked-stats {
  display: grid;
  padding-top: 3rem;
  padding-left: 1rem;
  grid-template-columns: 1.5fr 1fr;
  line-height: 3rem;
  font-size: 1.1rem;
  font-weight: bold;
}

.casual {
  background-color: #5b6467;
  background-image: linear-gradient(315deg, #5b6467 0%, #8b939a 74%);
  padding: 2rem;
  border-radius: 5px;
  margin-bottom: 10srem;
}

.casual-stats {
  display: grid;
  padding-top: 3rem;
  padding-left: 1rem;
  grid-template-columns: 1.5fr 1fr;
  line-height: 3rem;
  font-size: 1.1rem;
  font-weight: bold;
}

/* LeaderBoard Styles */

.dd-header {
  margin-left: 2rem;
}

.dd-header-title {
  color: #333;
  font-weight:bold;
  font-size:2rem;
  background-color: #5b6467;
  background-image: linear-gradient(315deg, #5b6467 0%, #8b939a 74%);
  width: 283px;
  padding: 1rem;
  border-radius: 5px
}

.dd-wrapper {
  color: black;
  width: 10.1rem;
  padding: 1rem;
  margin: 2rem;
}

.dd-list-item {
  padding: 1rem;
  border-bottom: 1px dotted #fff;
  margin-left: -1rem;
  margin-right: -1rem;
  width: 10rem;
}

.dd-list-item button {
  background: unset;
  border: unset;
  outline: unset;
}

.dd-list-item span {
  padding: 2px;
}

.dd-list-item li {
  background: none;
}

/* Leaderboard Section */

.leaderboard {
  padding-top: 2rem;
  display: flex;
  padding: 3rem;
  background-color: #5b6467;
  background-image: linear-gradient(315deg, #5b6467 0%, #8b939a 74%);
  margin: 2rem 2rem;
  max-width: 1980px;
  border-radius: 5px;
}

.leaderboard-section {
  display: flex;
}

.position-wrapper {
  height: 6rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
  border-radius: 50%;
}

.leaderboard-position {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 2rem;
}

.leaderboard-section img {
  background-color: black;
  border: 1px;
  border-radius: 50%;
  margin: 0 4rem;
}

.leaderboard-info {
  display: flex;
  font-weight: bold;
  font-size: 2rem;
  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
  border-radius: 5px;
}

.leaderboard-info div {
  margin-top: 1rem;
  margin-left: 3rem;
  padding-top: 2.3rem;
  margin-right: 2rem;
}

/* Login Styles */

form {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  background-color: #5b6467;
  background-image: linear-gradient(315deg, #5b6467 0%, #8b939a 74%);
  padding: 3rem;
  border-radius: 5px;
  border: unset;
  margin-bottom: 5rem;
}

.container h1 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
}

.container p {
  text-align: center;
}

.container label {
  padding-top: 2rem;
  padding-bottom: 0.3rem;
  font-weight: bold;
  font-size: 1.5rem;
}

.container input {
  height: 4vh;
  padding-left: 0.5rem;
  border-radius: 5px;
  border: unset;
  color: #333;
}

.login-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.login-register-btns {
  height: 4rem;
  width: 8rem;
  color: #333;
  margin: 2rem 2rem;
  border: unset;
  text-decoration: unset;
  outline: unset;
  border-radius: 5px;
}

.login-register-btns:hover {
  cursor: pointer;
  background-color: #5b6467;
  color: #fff;
}

/* Register Styles */

.submit {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

/* Logout Button */

.logout-btn {
  height: 4rem;
  width: 8rem;
  color: #333;
  margin: 0;
  border: unset;
  text-decoration: unset;
  outline: unset;
  border-radius: 5px;
}

.logout-btn:hover {
  cursor: pointer;
  background-color: #5b6467;
  color: #fff;
}

.logout-btn-wrapper{
  margin-top: -0.8rem;
}
